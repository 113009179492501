const gateway_url = "https://sasocialdev.sas.ips.pt";
const packageVersion = require("../../package.json");

export const environment = {
  production: false,
  version: packageVersion.version,
  institute: "IPSetubal",
  instituteFull: "Instituto Politécnico de Setúbal",
  institueDescription: "",
  hasSSO: true,
  hasCC: false,
  ccClientId: '',
  ccHost: 'https://preprod.autenticacao.gov.pt',
  useLDAP: false,

  faultComunicationLink: 'https://forms.gle/5NydgVdQMvHyewCk6',
  recaptcha: {
    siteKey: '6LcoB9UlAAAAAK3HN4kiMpyoy8Hxc47xnyz6pRSi',
  },

  showQrCodeLink: true,
  showBankLogosOnChargePage: false,

  mobility_regulation_url: "",

  host: gateway_url,
  version_check_url: gateway_url.concat("/assets/version.json"),
  auth_api_url: gateway_url + "/api/authorization",
  conf_api_url: gateway_url + "/api/configuration",
  media_api_url: gateway_url + "/api/media",
  media_url: gateway_url + "/media",
  communication_api_url: gateway_url + "/api/communication",
  alimentation_api_url: gateway_url + "/api/alimentation",
  //alimentation_api_url: 'http://0.0.0.0:7080/api',
  bus_api_url: gateway_url + "/api/bus",
  private_accommodation_api_url: gateway_url + "/api/private_accommodation",
  accommodation_api_url: gateway_url + "/api/accommodation",
  infrastructure_api_url: gateway_url + "/api/infrastructure",
  u_bike_api_url: gateway_url + "/api/u_bike",
  sport_api_url: gateway_url + "/api/sport",
  calendar_api_url: gateway_url + "/api/calendar",
  current_account_api_url: gateway_url + "/api/current_account",
  payments_api_url: gateway_url + "/api/payments",
  notifications_api_url: gateway_url + "/api/notifications",
  queues_api_url: gateway_url + "/api/queue",
  gamification_api_url: gateway_url + "/api/gamification",
  social_support_api_url: gateway_url + "/api/social_scholarship",
  volunteering_api_url: gateway_url + "/api/volunteering",
  mentoring_api_url: gateway_url + "/api/mentoring",
  mobility_api_url: gateway_url + "/api/bus",
  reports_api_url: gateway_url + "/api/reports",
  health_api_url: gateway_url + "/api/health",
  emergency_fund_api_url: gateway_url + "/api/emergency_fund",
  requests_api_url: gateway_url + "/api/requests",
  refill_h2o_api_url: gateway_url + "/api/refill-h2o",
  maintenance_api_url: gateway_url + "/api/maintenance",
  job_portal_api_url: gateway_url + "/api/job_portal",
  career_management_api_url: gateway_url + "/api/career_management",
};
